import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import "./international.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { Loader } from "../loader/Loader";
import { useNavigate } from "react-router-dom";

import Label2Image from "../../assets/images/Label2image.png";
import { RedirectUrlAfterLogin } from "../repeatComponent/RedirectUrlAfterLogin";
interface Country {
  id: string;
  name: string;
  tour_image: string;
  location: string;
}

function International() {
  const [isShowMore, setIsShowMore] = useState(false);
  const [load, setLoad] = useState<boolean>(true);
  const [cardsData, setCardsData] = useState(false);
  const [countriesData, setCountriesData] = useState<Country[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoad(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/getAllInternationalTripList`
      );
      if (response.data.success) {
        setCountriesData(response.data.data);
        setLoad(false);
      } else {
        console.error("Failed to fetch data:", response.data.error);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  function formatTotalAmount(amount: any) {
    amount = parseFloat(amount);

    if (isNaN(amount)) {
      return "0";
    }

    return amount.toLocaleString("en-IN");
  }

  return (
    <>
      <RedirectUrlAfterLogin />
      {load ? <Loader /> : ""}
      <div className="container mt-5 for_destination">
        <h3 className="text-center for_backpacking">
          <span style={{ color: "rgba(220, 27, 94, 1)" }}>International </span>{" "}
          Departures
        </h3>
      </div>

      <section className="mt-2 mb-4">
        <div className="container">
          <div className="row"></div>

          <div className="mt-4">
            <div className="row row-cols-1 row-cols-md-4 g-4">
              {countriesData.map((country: any, index: number) => (
                <div
                  key={index}
                  className="col-lg-4 col-md-4 col-sm-12 col-12 mb-2 mt-4"
                >
                  {/* <Link
                    to={`/tripdetails/${country._id}`}
                    className="featuredlink"
                  > */}
                  <div
                    className="card border-0 for_card_inter mb-1"
                    onClick={() => {
                      const id = country._id;
                      const slugUrl = country?.title

                        ?.toLowerCase() // Convert to lowercase
                        .replace(/\s+/g, "-");
                      navigate(`/tripdetails/${slugUrl}`, {
                        state: id,
                      });
                    }}
                  >
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${country?.tour_image?.[0]?.src}`}
                      className="card-img-top-inter2 card-img2"
                      alt="Trip"
                    />
                    <div className="for_visitdays mb-4">
                      <h5 className="for_h5">
                        {country.tourtype === "normal"
                          ? "Group"
                          : country.tourtype}
                      </h5>
                    </div>
                    {country?.tagLabelNames?.[0] && (
                      <div className="for-sold-out2 mb-4">
                        <img src={Label2Image} className="img-fluid" />
                        <div className="LabelName">
                          {country.tagLabelNames[0]}
                        </div>
                      </div>
                    )}

                    <div className="dayandnightw">
                      <p>
                        {country.nightorday}N/{country.nightorday + 1}D
                      </p>
                    </div>
                    <div className="card-body">
                      <div className="card-text">
                        <div className="main_para justify-content-between">
                          <h5 className="loremforfeatured">
                            {country.title.split(" ").slice(0, 6).join(" ")}
                            {country.title.split(" ").length > 6 ? "..." : ""}
                          </h5>
                          <p className="fetured_para">
                            {country.location.split(" ").slice(0, 4).join(" ")}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-baseline mt-2">
                          <p className=" startrate ">
                            {country.tourtype === "normal"
                              ? `Start from ₹${formatTotalAmount(
                                  country?.packageType_price
                                )}`
                              : "Customizable Price"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default International;
