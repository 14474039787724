import React, { useState, useEffect } from "react";
import "./MainGeneral.css";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { useParams } from "react-router-dom";
import AddOn from "./AddOn";

interface AddOnProps {
  firstname: string;
}

const MainGeneral = (props: any) => {
  const pdfUrl = `https://api.glabol.com/${props.trav.pdf_url ?? ""}`;
  let TotalAmt =
    (props?.trav?.finalPrice || 0) - (props?.trav?.appliedCoupon || 0);
  let pendingAmt = 0;
  const addonpaidamt = props?.trav?.amountPaid - props.trav.TourPrice;

  const amountRemaining =
    (props?.trav?.amountPaid || 0) - addonpaidamt - pendingAmt;
  return (
    <>
      <form className="row g-3  mb-4 pt-4 formgeneral pb-4">
        <div className="col-md-4 ">
          <label className="form-label label-general">First Name</label>
          <input
            type="text"
            style={{ background: "#FFEAC9" }}
            className="form-control placegeneral "
            id="inputEmail4"
            value={props?.trav?.user_id?.firstname}
            // readOnly
          />
        </div>

        <div className="col-md-4 ">
          <label className="form-label label-general">Last Name</label>
          <input
            type="text"
            style={{ background: "#FFEAC9" }}
            className="form-control placegeneral "
            id="inputEmail4"
            value={props?.trav?.user_id?.lastname}
            // readOnly
          />
        </div>
        <div className="col-md-4">
          <label className="form-label label-general">Order ID</label>
          <input
            type="text"
            style={{ background: "#FFEAC9" }}
            className="form-control placegeneral"
            id="inputEmail4"
            value={props?.trav?.order_id?.slice(0, 20)}
            // readOnly
          />
        </div>

        <div className="col-md-4 ">
          <label className="form-label  label-general">Number of Members</label>
          <input
            type="text"
            style={{ background: "#FFEAC9" }}
            className="form-control placegeneral "
            id="inputEmail4"
            value={props?.trav?.old_travel_no_of_traveler}
            // readOnly
          />
        </div>
        <div className="col-md-4 ">
          <label className="form-label  label-general">Boarding Location</label>
          <input
            type="text"
            style={{ background: "#FFEAC9" }}
            className="form-control placegeneral "
            id="inputEmail4"
            value={props?.trav?.travel_boarding_point?.boarding}
          />
        </div>
        <div className="col-md-4 ">
          <label className="form-label  label-general">Date</label>
          <input
            type="text"
            style={{ background: "#FFEAC9" }}
            className="form-control placegeneral "
            id="inputEmail4"
            value={props?.trav?.travel_date}
          />
        </div>
      </form>

      <form className="row g-3  mb-2 pt-1 formgeneral2">
        <div className="col-md-12 ">
          <label className="form-label  label-general2 pb-2">
            Payment Status :
            <span className="Partial-text">
              &nbsp; {props?.trav?.payment_status}
            </span>
          </label>
        </div>
        <div className="col-md-4 ">
          <label className="form-label  label-general3">
            Amount Paid (Tour)
          </label>
          <input
            type="text"
            className="form-control placegeneral3 "
            id="inputEmail4"
            value={amountRemaining}
            readOnly
          />
        </div>
        <div className="col-md-4">
          <label className="form-label label-general3">Pending Amount</label>
          <input
            type="text"
            className="form-control placegeneral3"
            id="inputEmail4"
            value={(() => {
              let pendingAmt = 0;
              if (props?.trav?.amountPaid < TotalAmt) {
                pendingAmt = TotalAmt - props?.trav?.amountPaid;
              }
              return Math.abs(pendingAmt || 0).toString();
            })()}
            readOnly
          />
        </div>

        <div className="col-md-4">
          <label className="form-label label-general3">Total Amount</label>
          <input
            type="text"
            className="form-control placegeneral3"
            id="inputEmail4"
            value={TotalAmt}
            readOnly
          />
        </div>

        {/* <div className="col col-lg-6">
          <p>(3% Transactional charges are applicable)</p>
          </div> */}

        <div className="col-md-3 pt-3 px-5 for-invoice-mobile m-auto text-center">
          <a
            href={pdfUrl}
            className="invoice-btn"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button>INVOICE</Button>
          </a>
        </div>
      </form>
    </>
  );
};

export default MainGeneral;
